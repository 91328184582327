.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px;
  overflow: hidden;
  
  .subContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    
    .samplesIndex, .sampleDetail {
      border: 1px solid #000000;
      height: 100%;
    }
    
    .samplesIndex {
      width: 40%;
      margin-right: 30px;
      
      .tableContainer {
        height: 100%;
        overflow-y: scroll;

        .table {
          margin-bottom: 50px;
          
          .th {
            border: 0px solid #000000;
          }
        }

      }
    }
    .sampleDetail {
      width: 60%;
      overflow-y: scroll;
    }

    .sectionTitle {
      margin: 10px;
    }
  }

}