@use '../branding';

.container {
    display: flex;
    justify-content: flex-start;
    gap: 0 branding.$double-spacing-unit;
    width: 100%;
    margin-top: branding.$double-spacing-unit;
    padding: 0 branding.$base-spacing-unit;
}

.searchContainer {
    margin: branding.$double-spacing-unit auto;
    padding: 0 branding.$base-spacing-unit;
}

.searchField {
    display: flex;
    column-gap: branding.$double-spacing-unit;
    max-width: 700px;
    margin: 0 auto;
}

.searchFilters {
    display: flex;
    column-gap: branding.$double-spacing-unit;
    justify-content: space-between;
    max-width: 850px;
    margin: branding.$double-spacing-unit auto;
    cursor: pointer;
    color: branding.$text-color;

    >div {
        text-align: center;
        font-size: branding.$h6-size;

        &:hover {
            color: branding.$primary-color;
        }
    }

    img.filterImage {
        display: block;
        width: 32px;
        height: 32px;
        margin: 0 auto .5rem;
    }
}

.sectionTitle {
    margin-bottom: branding.$base-spacing-unit;
    color: branding.$accent-color2;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
}

.map {
    flex-grow: 1;
    min-width: 50%;
    border-radius: branding.$border-radius;
}

.quests {
    flex-grow: 1;
    height: 450px;
}

.questsContainer {
    display: flex;
    column-gap: branding.$double-spacing-unit;
    flex-wrap: wrap;
}

.searchHeadingMobileOnly {
  display: none;
}

@media only screen and (max-width: 37.5em) {
    .searchHeadingMobileOnly {
        display: block;
    }

    .searchFilters {
        overflow: auto;
        margin: branding.$base-spacing-unit;
    }

    .map {
      display: none;
    }

    .questsContainer {
        display: flex;
        column-gap: branding.$double-spacing-unit;
        overflow: auto;
    }
}

.marker_container {
    max-width: 300px;
    max-height: 200px;
    overflow: auto;
    padding: branding.$base-spacing-unit;
    background-color: #fff;
    border: 1px solid #001838;
    border-radius: branding.$border-radius;
    box-shadow: branding.$box-shadow;
    font-size: branding.$xsmall-size;
}

.marker_container_heading {
    font-size: branding.$h3-size;
    margin: 0 0 branding.$half-spacing-unit;
}