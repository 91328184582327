@use '../../branding';

.modal_container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .5);
}

.modal_title {
    display: flex;
    column-gap: 1rem;
    margin: -1.05rem -1rem 1rem -1rem;
    padding: .5rem 1rem;
    background-color: branding.$primary-color;
    border-radius: branding.$border-radius branding.$border-radius 0 0;
    color: #fff;
}

.title {
    flex-grow: 1;
}

.close {
    display: block;
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(255, 255, 255, .25);
    border: 2px solid #aa7878;
    padding: 2px 4px 4px;
    line-height: 1;
}

.modal_content {
    position: relative;
    z-index: 2;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 95vw;
    max-height: 95vh;
    margin: 0 auto;
    background-color: #fff;
    padding: 1rem;
    // border: 2px solid branding.$primary-color;
    box-shadow: branding.$box-shadow;
    border-radius: branding.$border-radius;

    &.darkMode {
        background-color: branding.$primary-color;
        color: branding.$color-white;
    }
}