@use '../branding';

.container {
  margin: branding.$base-spacing-unit 0;
}

.quest_image_hero {
  position: relative;
  height: 250px;
  margin: 0 0 branding.$double-spacing-unit;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: 0 0 branding.$base-spacing-unit 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .25;
    background-image: url(../../public/assets/images/backgrounds/bg-topagraphy.svg);
  }
}

.questTitleContainer {
  display: flex;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 65px;

  >div:first-child {
    flex-grow: 1;
  }

  >div:last-child {
    margin-bottom: branding.$double-spacing-unit;
  }
}

.grid {
  display: flex;
  column-gap: branding.$double-spacing-unit;
  margin-top: branding.$double-spacing-unit;
}

.uploadContainer {
  width: 450px;
  margin: 0 auto;
  padding: branding.$base-spacing-unit branding.$double-spacing-unit;
  background-color: branding.$seconday-color;
  border-radius: branding.$border-radius;
}

.dataset {
  width: 400px;
  margin: 0 auto branding.$double-spacing-unit;
  padding: branding.$base-spacing-unit branding.$double-spacing-unit;
  border-radius: branding.$border-radius;
  background-color: #E3E5E8;

  h2 {
    font-size: branding.$h3-size;
    text-align: center;
    text-transform: uppercase;
  }

  .measurements {
    display: flex;
    column-gap: branding.$double-spacing-unit;
    // display: grid;
    // grid-template-columns: auto auto 1fr;
    // grid-template-rows: min-content 1fr;
    // grid-template-areas:
    //   "stat1 stat2 images"
    //   "stat3 stat4 images";
    // gap: 0 branding.$double-spacing-unit * 1.5;
    // flex-wrap: wrap;

    >div {
      h3 {
        margin-bottom: 0;
        font-size: branding.$small-size;
        text-transform: uppercase;
        font-weight: 300;
      }
    }

    .properties {
      display: flex;
      flex-wrap: wrap;
      width: 60%;
      gap: branding.$base-spacing-unit;

      >div {
        width: calc(50% - branding.$base-spacing-unit);
      }
    }

    // .stat1 {
    //   grid-area: stat1;
    // }

    // .stat2 {
    //   grid-area: stat2;
    // }

    // .stat3 {
    //   grid-area: stat3;
    // }

    // .stat4 {
    //   grid-area: stat4;
    // }

    .images {

      >img,
      .thumbnail {
        display: block;
        border: 2px solid branding.$color-white;
        border-radius: branding.$border-radius;
        width: 60px;
        max-height: 60px;
        height: auto;
        object-fit: cover;
        object-position: top;
        cursor: pointer;
      }

      // grid-area: images;
    }
  }
}

.completedMessage {
  width: 100%;
  padding: branding.$double-spacing-unit;
  background-color: branding.$seconday-color;
  border-radius: branding.$border-radius;
  text-align: center;
  color: branding.$color-black;
  font-family: branding.$heading-font;
}

.formItem {
  margin-bottom: branding.$base-spacing-unit;

  input {
    font-weight: 500;
  }

  input {
    width: 100%;
    padding: branding.$half-spacing-unit branding.$base-spacing-unit;
    background-color: branding.$field-color;
    border: none;
    border-radius: branding.$border-radius;
    color: branding.$accent-color2;
    font-weight: 300;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
    }

    &::placeholder {
      color: branding.$accent-color2;
      font-weight: 300;
    }
  }
}

.formError {
  color: branding.$error-color;
  font-size: .8rem;
  margin: 0;
}

@media only screen and (max-width: 37.5em) {
  .grid {
    display: block;
    width: 100%;
    padding: 0 branding.$double-spacing-unit;

    .uploadContainer {
      width: 100%;
      margin: branding.$double-spacing-unit 0;
    }

    .dataset {
      width: 100%;
    }
  }
}