@use 'branding';

body {
  background-color: branding.$color-white;
  font-family: branding.$body-font;
  color: branding.$text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: branding.$heading-font;
  color: branding.$primary-color;
}

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.app-container {

  >header,
  >main,
  >footer {
    flex-basis: 0;
  }

  >main {
    flex-grow: 1;
    overflow: auto;
    width: 1440px;
    margin: 0 auto;
    background-color: branding.$color-white;
  }

  >footer {
    padding: branding.$base-spacing-unit;
    background-color: branding.$primary-color;
    color: branding.$color-white;

    >div {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 87.5em) {
  .app-container>main {
    width: 100%;
  }
}