.sampleDetailContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;

  
  .sampleDetailBody {
    display: flex;
    width: 100%;

    .verticalDivider {
      border-left: 1px solid #000000;
      height: 100;
      margin: 0 10px;
    }
    
    .sampleMetaData, .sampleData {
      height: 100%;
      padding: 10px 10px;
    }
    
    .sampleMetaData {
      width: 40%;
      
      .sampleMetaDataItem {
        margin-bottom: 30px;
      }

    }
    .sampleData {
      width: 60%;
      .sampleImage {
        width: 100%;
        height: auto;
      }
      .actionsContainer {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1rem;
        width: 100%;
        div {
          border: 1px solid #000000;
          padding: 5px;
          height: 50x;
        }
      }
      .additionalDataItem {
        margin-bottom: 30px;
      }

      .plantIdResultsContainer {

        
        .plantIdResultContainer {
          &:nth-child(even) {
            background-color: #f3f3f3;
          }
          
          &:nth-child(odd) {
            background-color: #e6e6e6;
          }
          margin-bottom: 2rem;
          
          .plantIdResultTitle {
            margin-bottom: 1rem;
          }
          .plantIdResult {
            margin-bottom: 1rem;
          }
        }
      }
    }

  }

  .title {
    font-weight: bold;
    margin: 0;
    font-size: 0.8rem;
  }

  .underline {
    text-decoration: underline;
  }
}