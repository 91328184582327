@use '../../branding';

.dropdown_container {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.dropdown_title {
    cursor: pointer;
}

.dropdown_content {
    position: absolute;
    top: 100%;
    z-index: 1000;
    padding: branding.$base-spacing-unit branding.$double-spacing-unit;
    border: 1px solid branding.$color-black;
    border-radius: branding.$border-radius;
    background: branding.$color-white;
    color: branding.$color-black;
    box-shadow: branding.$box-shadow;
    text-align: left;
}