@use '../branding';

/* Profile.css */
.container {
  display: grid;
  grid-template-columns: 350px auto;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "profile content";
  gap: 0 branding.$double-spacing-unit;
  width: 1440px;
  margin: 0 auto;
  padding: 0 branding.$base-spacing-unit;
  background-color: branding.$color-white;
  height: 100vh;
}

.profileContainer {
  grid-area: profile;
  height: 100vh;
  padding: branding.$double-spacing-unit;
  background-color: #FAFCFF;
  background-image: url(../../public/assets/images/backgrounds/bg-topagraphy.svg);
  background-size: contain;
}

.main-content {
  grid-area: content;
}

.active-quests {
  display: flex;
  column-gap: branding.$double-spacing-unit;
  width: 100%;
  overflow: auto;
  height: 100px;
  text-wrap: nowrap;
}

.completed-quests {
  display: flex;
  height: 150px;
  overflow-x: auto;
  text-wrap: nowrap;
}

.card,
.item {
  border: 1px solid black;
  padding: branding.$base-spacing-unit;
  margin: branding.$base-spacing-unit;
  flex: 0 0 30%;
  /* This sets the width of each quest block to 30% of the container's width */
}

.profile-picture {
  display: block;
  width: 169px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin: branding.$double-spacing-unit;
}

@media only screen and (min-width:75em) and (max-width: 90em) {
  .container {
    width: 1200px;
  }
}

@media only screen and (min-width:64em) and (max-width: 75em) {
  .container {
    width: 1000px;
  }
}

@media only screen and (min-width:37.5) and (max-width: 64em) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 37.5em) {
  main {
    margin: 0;
    padding: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .main-content {
    flex: auto;
  }

  .sidebar {
    display: none;
  }
}