.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .items {
    height: 55%;
    overflow-y: auto;
    border: 3px solid rgb(76, 76, 76);
    padding: 5px;
    .titleContainer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    
    .tableContainer {
      height: 90%;
      overflow-y: auto;
      display: inline-block;
      table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
      }
    }
  }
}

