@use '../branding';

.quest_image_hero {
    position: relative;
    height: 200px;
    margin: 0;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin: 0 0 branding.$base-spacing-unit 0;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .25;
        background-image: url(../../public/assets/images/backgrounds/bg-topagraphy.svg);
    }
}

.questTitleContainer {
    display: flex;
    align-items: center;
    // align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 9%;

    >div:first-child {
        flex-grow: 1;
    }

    // >div:last-child {
    //     margin-bottom: branding.$double-spacing-unit;
    // }
}

.container {
    // width: 100%;
    width: 84%;
    margin: 0 auto;
    height: calc(100% - 285px);
}

.difficultyMarkerContainer {
    display: flex;
    column-gap: branding.$half-spacing-unit;
    margin: branding.$half-spacing-unit 0;
}

.difficultyMarker {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: #D9D9D9;

    &.is-selected {
        background-color: #75FB93;
    }
}

.questInfo {
    display: flex;
    justify-content: space-between;
    // column-gap: 48px;
    margin: 0 0 branding.$double-spacing-unit;
    background-color: branding.$seconday-color;
    // padding: branding.$base-spacing-unit branding.$double-spacing-unit;
    padding: 15px 9%;
    font-family: branding.$heading-font;

    h2 {
        margin: 0;
        font-size: branding.$small-size;
        color: branding.$text-color;
        text-transform: uppercase;
        font-weight: 700;
    }

    .questTypeIcon {
        display: inline-block;
        height: 20px;
        vertical-align: top;
        margin-right: branding.$half-spacing-unit;
    }

    .questType {
        font-size: branding.$h4-size;
        line-height: 1;
        color: branding.$primary-color;
        font-weight: 300
    }
}

.content {
    display: flex;
    width: 100%;
    column-gap: branding.$double-spacing-unit;

    >div {
        padding: branding.$base-spacing-unit;
        flex: 1;
    }

    .sectionTitle {
        margin: 0 0 branding.$base-spacing-unit;
        padding-bottom: 2px;
        border-bottom: 1px solid branding.$color-black;
        text-transform: uppercase;
        font-size: branding.$paragraph-size;
    }

    .subsectionTitle {
        margin: 0;
        font-size: branding.$small-size;
        color: branding.$text-color;
        text-transform: uppercase;
        font-weight: 300;
    }

    .overview {
        max-width: 20%;
        margin-right: 50px;
    }

    .safety_information {
        display: flex;
        flex-direction: column;
        row-gap: branding.$base-spacing-unit;
        width: 250px;
        max-width: 250px;
        background-color: branding.$seconday-color;
        border-radius: branding.$border-radius;
    }

    &.share_row {
      justify-content: end;
    }

    .row_1_left, .row_1_right {
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0;
      }
      h3 {
        margin-bottom: 15px;
        font-size: branding.$h2-size;
      }

    }
    .cell_content {
      a {
        color: branding.$primary-color;
      }
    }
    .row_1_left {
      .cell_content {
        border-bottom: 1px solid black;
        margin-bottom: 30px;
        
      }
      h5 {
        font-weight: 100;
        font-size: branding.$h3-size;
      }
    }
    .row_1_right {
      .cell_content {
        margin-bottom: 45px;
      }
      div {
        font-family: branding.$heading-font;
        font-weight: 300;
      }
    }
    &.row_2 {
      flex-direction: column;
      h2 {
        padding: 0;
        padding-left: branding.$base-spacing-unit;
        padding-right: branding.$base-spacing-unit;
        margin-bottom: 15px;

      }
    }
    &.leave_no_trace, &.data_collection_details_container, &.risk_management {
      div {
        margin: branding.$base-spacing-unit;
      }
    }
    &.map_row_container {
      position: relative;
      height: 450px; 
      column-gap: branding.$half-spacing-unit;
      .map_container {
        padding: 0;
        margin: branding.$base-spacing-unit;
        border-radius: branding.$border-radius;
        overflow: hidden;
        width: 50%;
        flex: none;
      }
    }
    .leave_no_trace_inner_container {
      height: 90px;
      border-radius: 10px;
      background-color: #F5F5F5;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: branding.$base-spacing-unit;
      
      .leave_no_trace_line {
        width: 1px;
        height: 95%;
        background-color: black;
        margin: 0 24px;
      }

      h5 {
        margin: 0;
      }
      span {
        font-weight: 300
      }
      a {
        color: branding.$primary-color;
      }
    }
    .quest_details_dropdowns {
      display: flex;
      flex-direction: column;
      .quest_details_dropdown {
        border-bottom: 1px solid black;
        .default_shown_container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 60px;
          h3 {
            text-transform: uppercase;
            font-weight: 100;
            margin: 0;
          }
        }
        .dropdown_content {
          margin-bottom: branding.$double-spacing-unit;
          padding-right: branding.$base-spacing-unit;
        }
      }
      // row-gap: branding.$base-spacing-unit;
    }
    .data_collection_details_inner_container {
      background-color: branding.$seconday-color;
      border-radius: branding.$border-radius;
      min-height: 200px;
      padding: branding.$triple-spacing-unit;
      
      ul {
        li {
          margin-bottom: branding.$base-spacing-unit;
        }
        padding-inline: 20px;
      }

      div {
        margin: 0;
        padding: 0;
      }
      .subhead {
        text-transform: uppercase;
        border-bottom: 1px solid black;
        font-weight: 300;
      }

      .details, .data_requirements {
        margin-bottom: branding.$triple-spacing-unit;
      }
    }

    .risk_management_inner_container {
      background-color: branding.$primary-color;
      border-radius: branding.$border-radius;
      color: branding.$color-white;
      font-family: branding.$heading-font;
      font-weight: 300;

      padding: branding.$triple-spacing-unit;
      
      h2 {
          color: branding.$color-white;
      }
      div {
        margin: 10px 0;
      }
  }
}

.quest_details {
    margin-top: 0;
    font-size: branding.$small-size;
}

.plusBackground {
    height: 35px;
    width: 100%;
    margin: branding.$double-spacing-unit 0;
    background-image: url(../../public/assets/images/backgrounds/bg-gray-plus-white.svg);
    background-size: cover;
}

.upload_field {
    border: 1px solid #999;
    background-color: #f6f6f6;
    border-radius: branding.$border-radius;
    padding: 100px 0;
    text-align: center;
    cursor: pointer;
}

.marker_container {
    max-width: 300px;
    max-height: 200px;
    overflow: auto;
    padding: branding.$base-spacing-unit;
    background-color: #fff;
    border: 1px solid #001838;
    border-radius: branding.$border-radius;
    box-shadow: branding.$box-shadow;
    font-size: branding.$xsmall-size;
}

.marker_container_heading {
    font-size: branding.$h3-size;
    margin: 0 0 branding.$half-spacing-unit;
}

.picture_box_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.supplemental_picture_box{
  // background-color: grey;
  border-radius: branding.$border-radius;
  // border: 1px solid black;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}



@media only screen and (max-width: 37.5em) {
    .questTitleContainer {
        flex-direction: column;
        justify-content: space-around;
        padding: 0 branding.$double-spacing-unit;
        vertical-align: middle;

        >div:first-child {
            flex-grow: revert;
        }

        >div:last-child {
            margin-bottom: branding.$double-spacing-unit;
        }
    }

    .questInfo {
        flex-direction: column;
        padding: branding.$base-spacing-unit;
        row-gap: branding.$half-spacing-unit;

        h2 {
            font-size: branding.$h4-size;
        }

        .questType {
            font-size: branding.$small-size;
        }
    }
    .content{

      .overview {
          max-width: 100%;
          margin-right: 0;
      }
      &.map_row_container {
        height: auto;
        .map_container {
          width: 100%;
          height: 350px;
          padding: 0;
          margin: 0;
        }
  
      }
      .picture_box_container {
        padding: 0;
        margin-top: branding.$base-spacing-unit;
      }
    }
    .share_row {
      margin-left: 5px;
      margin-bottom: 7px;
    }


    .container {
        width: 94%;
        display: flex;
        flex-direction: column;
        height: auto;

        .content,
        .upload {
            display: block;
            width: 100%;
        }

        .content {
            border: none;

            >div {
                width: 100%;
            }

            .overview {
                margin-bottom: 0;
                padding-top: 0;
            }

            .safety_information {
                width: 100%;
                max-width: 100%;
                margin-bottom: branding.$double-spacing-unit;
            }

            &.leave_no_trace, &.data_collection_details_container, &.risk_management {
              div {
                margin: branding.$base-spacing-unit 0 0 0;
                
              }
              .leave_no_trace_inner_container {
                height: auto;
              }
              .data_collection_details_inner_container {
                padding: branding.$base-spacing-unit;
              }
            }
            &.risk_management {
              margin-bottom: branding.$base-spacing-unit;
            }


            // .risk_management {
            //     width: 100%;
            //     max-width: 100%;
            // }
        }

        .upload {
            padding-bottom: branding.$double-spacing-unit;
        }
    }

    .map {
        width: calc(100% - 24px);
        margin: 12px;
        flex-grow: initial;
    }
    
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.signup_success {
    margin-right: branding.$double-spacing-unit;
    padding: branding.$base-spacing-unit;
    background-color: branding.$success-color;
    color: branding.$seconday-color;
    border: 1px solid branding.$accent-color2;
    border-radius: branding.$border-radius;
    animation: fadeOut 8s;
    opacity: 0;
}

.additionalDataItem {
    margin-bottom: 10px;

    .underline {
        text-decoration: underline;
    }

    .bold {
        font-weight: bold;
    }

    p {
        font-size: .8rem;
        margin: 0;
    }
}

.formError {
    color: branding.$error-color;
    font-size: .8rem;
    margin: 0;
}