@use '../../branding';

.pageheaderBackground {
  position: relative;
  padding-bottom: branding.$base-spacing-unit;
  border-bottom: 2px solid branding.$seconday-color;

  &::before {
    content: "";
    background-image: url(../../../public/assets/images/backgrounds/bg-topagraphy.svg);
    background-size: contain;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.33;
    z-index: -1;
  }
}

.app-container>header {
  flex-basis: 0;
}

.pageheader-container {
  display: flex;
  column-gap: branding.$base-spacing-unit;
  align-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: branding.$base-spacing-unit;
}

.pageheader-nav {
  flex-grow: 1;
  margin-right: branding.$double-spacing-unit;
  text-align: end;
}

.nav-item {
  display: inline-block;
  padding: branding.$base-spacing-unit branding.$double-spacing-unit;
  background-color: branding.$seconday-color;
  border-radius: branding.$double-spacing-unit;
  text-decoration: none;
  font-weight: 600;
  color: branding.$color-black;
}

.admin-nav-item {
  border-bottom: 2px solid branding.$primary-color;
  color: branding.$color-black;
}

.menu-item {
  position: relative;
  display: block;
  margin: 0 (branding.$double-spacing-unit * -1);
  padding: branding.$base-spacing-unit branding.$double-spacing-unit;
  text-wrap: nowrap;
  text-decoration: none;
  font-size: branding.$small-size;
  color: branding.$text-color;
  cursor: pointer;

  &:first-child {
    border-radius: branding.$border-radius branding.$border-radius 0 0;
  }

  &:last-child {
    border-radius: 0 0 branding.$border-radius branding.$border-radius;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      left: branding.$base-spacing-unit;
      bottom: 0;
      height: 1px;
      width: calc(100% - branding.$double-spacing-unit);
      border-bottom: 1px solid branding.$text-color;
    }
  }

  &:hover {
    background-color: lighten(branding.$accent-color2, 21%);
    font-weight: 500;
  }
}

.nobos-logo {
  display: block;
  height: 45px;
  margin-right: 31px;
}

.headerContent {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "welcome title"
    "welcome quests";
  gap: 0 branding.$double-spacing-unit;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 branding.$base-spacing-unit;
}

.headerContentPlain {
  display: block;
}

.welcome {
  grid-area: welcome;
}

.continue {
  grid-area: title;
  margin-bottom: 0;
}

.my_quests {
  grid-area: quests;
  display: flex;
  column-gap: branding.$double-spacing-unit;
  width: 100%;
  height: 125px;
  overflow: auto;
  text-wrap: nowrap;
}

@media only screen and (max-width: 37.5em) {
  .pageheaderBackground {
    border-bottom: 0;
  }
  .headerContent {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "welcome"
      "title"
      "quests";
    gap: 0;
  }

  .my_quests {
    height: 100px;
  }

  .site-links {
    display: none;
    visibility: hidden;
  }
}

.pill {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  column-gap: branding.$half-spacing-unit;
  padding: branding.$half-spacing-unit;
  background-color: branding.$accent-color2;
  border-radius: branding.$border-radius;
  color: branding.$color-white;
  line-height: 1;
  box-shadow: branding.$box-shadow-generic;
}

.pillSmall {
  font-size: branding.$xsmall-size;
}

.statusPill {
  position: absolute;
  bottom: branding.$base-spacing-unit;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
  opacity: .85;
}