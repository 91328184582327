@use '../../../branding';

.container {
  width: 1200px;
  max-width: 100%;
  height: 95%;
  
  form {
    overflow: scroll;
    h5 {
      margin: 0;
    }
  
    .formItem {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      padding: 5px;
      
      &.half {
        width: 50%;
      }
    }

    .formSubsection {
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: 100%;
      
      .formSubsectionItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .formSubsectionItemWithError {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
      }

      input, select {
        width: 85%;
      }
    }
  
    p {
      color: branding.$error-color;
      text-align: right;
      font-size: 0.8rem;
      font-weight: 700;
    
      &.success {
        color: branding.$success-color;
      }
    }
  
    label {
      font-size: 0.8em;
      font-weight: bold;
    }

    input[type="submit"] {
      /* width: 100%; */
      margin: 5px;
      background-color: #4CAF50;
      color: branding.$color-white;
      border: none;
      cursor: pointer;
      &:disabled {
        background-color: branding.$accent-color;
        cursor: not-allowed;
      }
    }
  }
}









