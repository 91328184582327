@use '../branding';

.container {
  .bold {
    font-weight: bold;
  }
  .header {
    font-size: 1.2rem;
    margin: 0;
  }
  
  h3 {
    margin-bottom: branding.$base-spacing-unit;
  }

  .resultsContainer {
    .remainingIds {
      margin-top: branding.$base-spacing-unit;
      font-size: 0.8rem;
    }
    .idResult {
      h4 {
        margin: 0
      }
      p {
        margin: 0;
      }
      margin-bottom: branding.$base-spacing-unit;
    }

  }

  button {
    cursor: pointer;
    &:disabled {
      background-color: #ccc;
      cursor: default;
    }
  }
}