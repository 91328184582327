@import url('https://fonts.googleapis.com/css2?family=Changa:wght@200..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

// Brand colors
$primary-color: #001838;
$seconday-color: #e8f2ff;
$accent-color: #75FB93;
$accent-color2: #9EA9B7;

// Generic colors
$color-white: #fff;
$color-black: #000;

// Other colors
$field-color: #F5F9FF;

// Text colors
$text-color: #3E4E58;
$error-color: red;
$success-color: green;

// Button colors
$button-primary: $accent-color;
$button-primary-hover: #60D47A;
$button-primary-active: #75FB93;
$button-primary-disabled: #D5F0DC;
$button-primary-text: $primary-color;
$button-secondary: $color-white;
$button-secondary-hover: #75FB93;
$button-secondary-active: #75FB93;
$button-secondary-disabled: #F7F7F7;
$button-secondary-text: $primary-color;

// Fonts
$heading-font: "Changa", sans-serif;
$button-font: "Changa", sans-serif;
$body-font: "Inter", sans-serif;

// Text size
$title-size: 3rem;
$h1-size: 2rem;
$h2-size: 1.5rem;
$h3-size: 1.25rem;
$h4-size: 1.125rem;
$h5-size: 1rem;
$h6-size: .875rem;
$paragraph-size: 1rem;
$small-size: .875rem;
$xsmall-size: .75rem;

// Spacing
$base-spacing-unit: 12px;
$half-spacing-unit: 6px;
$double-spacing-unit: 24px;
$triple-spacing-unit: 36px;

// Line height
$base-line-height: 1.5;

// Other
$border-radius: 7px;
$box-shadow: 2px 4px 4px rgba(0, 0, 0, .25);
$box-shadow-generic: 0 0 4px rgba(0, 0, 0, .25);