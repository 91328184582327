@use '../../branding';

.card {
    display: flex;
    flex-direction: column;
    font-family: branding.$heading-font;

    &.row {
        flex-direction: row;
        column-gap: branding.$base-spacing-unit;
    }
}

.cardImage {
    position: relative;
    width: 215px;
    height: 130px;
    margin-bottom: branding.$half-spacing-unit;
    border-radius: branding.$border-radius;
    background-size: cover;
    background-position: center;
}

.cardHeader {
    font-size: branding.$paragraph-size;
}

.cardContent {
    padding: branding.$half-spacing-unit 0;
    font-size: branding.$small-size;
}

.cardFooter {
    font-size: branding.$xsmall-size;
    font-weight: 300;
}