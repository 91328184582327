@use '../../branding';

.button {
    padding: branding.$base-spacing-unit branding.$double-spacing-unit;
    background-color: branding.$button-primary;
    color: branding.$button-primary-text;
    border: none;
    cursor: pointer;
    font-family: branding.$button-font;

    &:hover {
        background-color: branding.$button-primary-hover;
    }

    &:active {
        background-color: branding.$button-primary-active;
        box-shadow: inset 0 3px 7px rgba(0, 0, 0, .25);
    }

    &:disabled {
        cursor: auto;
        background-color: branding.$button-primary-disabled;
        color: branding.$text-color;
    }

    &.rounded {
        border-radius: branding.$border-radius;
    }
}

.button--secondary {
    background-color: branding.$color-white;
    color: branding.$primary-color;
}

.button--plain {
    border: none;
    background-color: transparent;
    color: branding.$primary-color;

    &:hover {
        text-decoration: underline;
    }
}

.button--icon_pill {
  padding: 2px;
  min-width: 100px;
  height: 28px;
  border-radius: 15px;
  border: 1px solid branding.$primary-color;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}