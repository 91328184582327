@use '../../branding';

.input {
    width: 100%;
    padding: branding.$half-spacing-unit branding.$base-spacing-unit;
    background-color: branding.$field-color;
    border: none;
    border-radius: branding.$border-radius;
    color: branding.$accent-color2;
    font-weight: 300;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
    }

    &::placeholder {
        color: branding.$accent-color2;
        font-weight: 300;
    }
}