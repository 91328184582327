.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.quests {
  height: 55%;
  overflow-y: auto;
  border: 3px solid rgb(76, 76, 76);
  padding: 5px;
}

.titleContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* margin-bottom: 5px; */
}

.titleContainer h1 {
  margin: 0;
}

.titleContainer a {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}

/* .questCreateFormContainer {
  min-height: 25%;
  border: 3px solid rgb(76, 76, 76);
} */

.tableContainer {
  height: 90%;
  overflow-y: auto;
  /* border: 3px solid rgb(76, 76, 76); */
  display: inline-block;
  /* -layout: fixed; */
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

caption {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}


thead > * {
  font-size: .8em;
  text-align: left;
}

thead th {
  border: 1px solid rgb(76, 76, 76);
  padding: 2px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #d3d3d3;
}

tbody tr {
  height: 30px;
}

tbody tr:nth-child(odd) {
  background-color: #f3f3f3;
}

tbody tr:nth-child(even) {
  background-color: #dadada;
}

tbody td {
  border: 1px solid rgb(238, 238, 238);
  padding: 2px;
}

.bottomThird {
  height: 43%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.adventurers {
  width: 49%;
  border: 3px solid rgb(76, 76, 76);
}

.datasets {
  width: 49%;
  border: 3px solid rgb(76, 76, 76);
}